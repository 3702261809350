<template>
  <div class="resource-image">
    <el-container>
      <el-header class="head-search">
        <el-row :gutter="24" style="width: 100%; text-align: left">
          <el-col :span="4">
            <el-select
              v-model="sourceType"
              placeholder="请选择查询图片使用类型"
              @clear="() => (this.useCount = 99)"
              clearable
              @change="() => initQueryImagePage()"
              style="width: 100%"
            >
              <el-option
                v-for="(source, index) in sourceOptional"
                :key="index"
                :label="source.split(':')[0]"
                :value="source.split(':')[1]"
              />
            </el-select>
          </el-col>
          <el-col :span="3">
            <el-input-number
              v-model="useCount"
              :min="0"
              :max="999"
              style="width: 100%"
              v-if="sourceType != null && sourceType !== ''"
              @change="initQueryImagePage"
            />
          </el-col>
          <el-col :span="11"></el-col>
          <el-col :span="6" style="text-align: right">
            <el-button type="primary" color="#EE82EE" style="color: white" @click="showUpload">
              <icon icon-name="UploadFilled" icon-size="20" icon-title="上传图片"></icon>
            </el-button>
          </el-col>
        </el-row>
      </el-header>
      <el-main>
        <ul v-infinite-scroll="loadImageScroll" :infinite-scroll-immediate="false">
          <li v-for="data in tableData" :key="data.serial">
            <el-card shadow="hover">
              <el-image lazy :src="data.imageUrl" :preview-src-list="[data.imageUrl]" fit="cover" />
              <div style="color: #8a8f97; font-size: 14px">
                <div class="bottom-title">
                  <em>图片名称：{{ data.imageName }}</em>
                  <em v-if="sourceType == null || sourceType === ''">
                    {{ changeSourceType(data.sourceType) }}
                  </em>
                  <em v-if="data.useCount || data.useCount === 0">使用数量：{{ data.useCount }}</em>
                </div>
                <div class="bottom-title">
                  <em>MD5值：{{ data.imageMd5 }}</em>
                  <el-popconfirm
                    confirm-button-text="确定"
                    cancel-button-text="取消"
                    v-if="data.useCount || data.useCount === 0"
                    icon-color="red"
                    title="确定删除该图片?"
                    @confirm="deleteImage(data)"
                  >
                    <template #reference>
                      <el-button type="text" class="button" :disabled="data.useCount !== 0">
                        删除
                      </el-button>
                    </template>
                  </el-popconfirm>
                </div>
              </div>
            </el-card>
          </li>
        </ul>
      </el-main>
      <el-footer></el-footer>
    </el-container>
    <app-upload
      ref="upload"
      :source-optional="sourceOptional"
      @queryImagePage="initQueryImagePage"
    ></app-upload>
  </div>
</template>

<script>
import icon from '@/components/icon'
import UploadImage from '@/views/resource/components/UploadImage'
import { deleteImage, imagePage } from '@/api/ResourceApi'

export default {
  name: 'ResourceImage',
  components: {
    icon,
    'app-upload': UploadImage
  },
  data() {
    return {
      current: 1,
      pageSize: 9,
      total: 0,
      sourceType: null,
      useCount: 99,
      tableData: [],
      sourceOptional: ['封 面:COVER', '内 容:CONTENT']
    }
  },
  methods: {
    showUpload() {
      this.$refs.upload.showDialog()
    },
    changeSourceType(type) {
      if (type === 'COVER') {
        return '封 面'
      } else if (type === 'CONTENT') {
        return '内 容'
      } else {
        return '其 他'
      }
    },
    initQueryImagePage() {
      this.current = 1
      this.tableData = []
      this.queryImagePage()
    },
    loadImageScroll() {
      if (this.total > this.current * this.pageSize) {
        this.current += 1
        this.queryImagePage()
      }
    },
    queryImagePage() {
      imagePage({
        pageNum: this.current,
        pageSize: this.pageSize,
        sourceType: this.sourceType,
        useCount: this.useCount
      }).then((res) => {
        if (res.code === 200) {
          this.tableData = this.tableData.concat(res.data.rows)
          this.current = res.data.currentPage
          this.total = res.data.total
        } else {
          this.$message({ message: res.msg, type: 'error' })
        }
      })
    },
    deleteImage(data) {
      if (data.useCount > 0) {
        this.$message({ message: '当前图片正在被使用中,无法删除！', type: 'warning' })
        return
      }
      deleteImage({
        serial: data.serial
      }).then((res) => {
        if (res.code === 200) {
          this.initQueryImagePage()
        } else {
          this.$message({ message: res.msg, type: 'error' })
        }
      })
    }
  },
  mounted() {
    this.initQueryImagePage()
  }
}
</script>

<style scoped>
.resource-image {
  width: 100%;
  min-width: 1600px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: #ffffff;
  border-radius: 5px;
}

.head-search {
  width: 100%;
  min-width: 700px;
  display: flex;
  align-items: center;
}

.bottom-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.resource-image >>> .el-main {
  height: 690px;
  min-width: 1600px;
  width: 100%;
}

.resource-image >>> .el-card {
  width: 30%;
  min-width: 450px;
  min-height: 360px;
  margin: 1.3%;
  float: left;
}

.resource-image >>> .el-image {
  width: 433px;
  height: 269px;
  margin: 0 auto;
}
</style>
