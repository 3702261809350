<template>
  <div class="resource-upload-image">
    <el-dialog v-model="dialogVisible" title="上传图片" width="30%">
      <el-form style="width: 100%; overflow: hidden">
        <el-form-item label="使用类型" label-width="80px">
          <el-select v-model="source" placeholder="请选择查询图片使用类型" style="width: 80%">
            <el-option
              v-for="(source, index) in sourceOptional"
              :key="index"
              :label="source.split(':')[0]"
              :value="source.split(':')[1]"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="图 片" label-width="80px">
          <UploadCropper @uploadImage="showImage" :imageShowUrl="imageUrl"></UploadCropper>
        </el-form-item>
      </el-form>
      <template #footer>
        <span>
          <el-button @click="dialogVisible = false">放 弃</el-button>
          <el-button type="primary" @click="uploadImage">提 交</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import UploadCropper from '@/components/upload/UploadCropper'
import { imageUpload } from '@/api/ResourceApi'

export default {
  name: 'UploadImage',
  components: {
    UploadCropper
  },
  props: {
    sourceOptional: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {
      dialogVisible: false,
      imageUrl: '',
      source: null,
      imageFile: null
    }
  },
  methods: {
    showDialog() {
      this.dialogVisible = true
    },
    showImage(image) {
      this.imageUrl = window.URL.createObjectURL(image)
      this.imageFile = image
    },
    uploadImage() {
      if (this.source == null) {
        this.$message({ message: '请选择使用类型！', type: 'warning' })
        return
      }
      if (this.imageFile == null) {
        this.$message({ message: '请选择图片！', type: 'warning' })
        return
      }
      const formData = new FormData()
      formData.append('sourceType', this.source)
      formData.append('image', this.imageFile)
      imageUpload(formData).then((res) => {
        if (res.code === 200) {
          window.URL.revokeObjectURL(this.imageUrl)
          this.imageUrl = ''
          this.source = null
          this.imageFile = null
          this.dialogVisible = false
          this.$message({ message: '上传成功', type: 'success' })
          this.$emit('queryImagePage')
        } else {
          this.$message({ message: res.msg, type: 'error' })
        }
      })
    }
  },
  mounted() {}
}
</script>

<style scoped></style>
